import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
export const DiplomaInVfxAndAnimation = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-vfx pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/vfx.jpg?v=0.1.9) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Diploma In VFX & Animation
                  </h2>
                  <p className="text-white">
                    If it can be imagined, it can be put on screen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title sub-section-title">
                    Diploma with Major in Vfx &amp; Animation
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Are you new to Vfx &amp; Animation in filmmaking? This
                      intensive Diploma Program is the only one of its kind to
                      provide the opportunity to work at Chroma Studio, to use
                      high end softwares for animation and vfx. You can shoot
                      the live sequence required for your Vfx on Super 35mm
                      cameras, Cine Lenses and by using complete professional
                      Filmmaking facilities.
                    </p>
                    <p>
                      In two semesters, you will make two short film project
                      with Vfx and Animation requirements. You will be a part of
                      script to screen process, which will help you gaining a
                      complete and hands on knowledge practically in the
                      important in Vfx &amp; filmmaking principles and learn the
                      cutting edge skills that equip you for an entry level job
                      in film, TV, Advertising and web industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Vfx &amp; Animation student will excel in his craft only
                      when he will master in all the filmmaking elements. An Vfx
                      Director must know the camera and Lenses, Lighting
                      Patterns, Editing, Dubbing, Vfx. Hence we provide all
                      standard filmmaking facilities to the Direction student
                      from day one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>In-depth, hands-on, practical training</li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>
                            Easy access to Chroma, Miniature, Dubbing, Editing
                            Studios
                          </li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Masterclass by Vfx Directors, Technicians from
                            Industry
                          </li>
                          <li>
                            Vfx Direction of two short films/semester compulsory
                          </li>
                          <li>
                            Chance to work as Assistant Vfx Director for Full
                            Length Feature Film of Graduation Students
                          </li>
                          <li>
                            Screening of Short Films made by you at Aurangabad
                            International Film Festival
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 1 Year (2
                            Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 2 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000/- + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <Link to="/contact-us">Click here</Link> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. Ginilal Salunkhe</span>{" "}
                            Vfx Director
                          </li>
                          <li>
                            <span className="fw-bold">2. Sunny Sharma</span>{" "}
                            Animation Artist
                          </li>
                          <li>
                            <span className="fw-bold">3. Nitin Baid</span>{" "}
                            Editor
                          </li>
                          <li>
                            <span className="fw-bold">4. Shiv Kadam</span>{" "}
                            Director, Writer, Academician
                          </li>
                          <li>
                            <span className="fw-bold">5. Jayprad Desai</span>{" "}
                            Producer, Director
                          </li>
                          <li>
                            <span className="fw-bold">6. Dnyanesh Zoting</span>{" "}
                            Producer, Director
                          </li>
                          <li>
                            <span className="fw-bold">7. Jaee Kadam</span>{" "}
                            Cinematographer, Executive Producer
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Chroma Studio</li>
                          <li>2. Miniature Studio</li>
                          <li>3. Vfx Lab</li>
                          <li>4. Film City</li>
                          <li>
                            5. Super 35mm Cameras, Cine Lights, Cine Lenses
                          </li>
                          <li>6. Dubbing Studio</li>
                          <li>7. Shooting Floors</li>
                          <li>8. Shooting Locations</li>
                          <li>9. Filmmaking Equipments</li>
                          <li>10. Costume Lab</li>
                          <li>11. Make-up Lab</li>
                          <li>
                            12. <Link to="/facilities">Click here</Link> to
                            check other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Mr. Mahesh Harbak</span> +91
                    9834602449
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
