import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Homepage } from "../Pages/Homepage/Homepage";
import { Helmet } from "react-helmet";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { MgmUniversity } from "../Pages/About Us/MgmUniversity/MgmUniversity";
import { OurPillars } from "../Pages/About Us/OurPillars/OurPillars";
import { AboutFilmArts } from "../Pages/About Us/AboutFilmArts/AboutFilmArts";
import { Faculty } from "../Pages/About Us/Faculty/Faculty";
import { DiplomainFilmmaking } from "../Pages/Academics/Programs/DiplomainFilmmaking";
import { GraduationProgram } from "../Pages/Academics/Programs/GraduationProgram";
import { MainFilmMaking } from "../Pages/Academics/Programs/MAinFilmMaking";
import { ShortPrograms } from "../Pages/Academics/Programs/ShortPrograms";
import { AdmissionProcedure } from "../Pages/Academics/Programs/AdmissionProcedure";
import { ActingForFilms } from "../Pages/Academics/Specialization/ActingForFilms";
import { CinematographyDI } from "../Pages/Academics/Specialization/CinematographyDI";
import { FilmDirectionScreenwriting } from "../Pages/Academics/Specialization/FilmDirectionScreenwriting";
import { FilmEditing } from "../Pages/Academics/Specialization/FilmEditing";
import { ProductionDesignArtDirection } from "../Pages/Academics/Specialization/ProductionDesignArtDirection";
import { SoundDesignMusicProduction } from "../Pages/Academics/Specialization/SoundDesignMusicProduction";
import { VFXAnimation } from "../Pages/Academics/Specialization/VFXAnimation";
import { Audition } from "../Pages/Activities/Audition";
import { Facilities } from "../Pages/Facilities/Facilities";
import { FilmProduction } from "../Pages/Activities/FilmProduction";
import { Contact } from "../Pages/Contact/Contact";
import { Workshop } from "../Pages/MiscellanousPages/Workshop";
import { WorkshopLeads } from "../Pages/MiscellanousPages/WorkshopLeads";
import { Scholarship } from "../Pages/MiscellanousPages/Scholarship";
import { ScholarshipLeads } from "../Pages/MiscellanousPages/ScholarshipLeads";
import { Gallery } from "../Pages/Activities/Gallery";
import { MgmFilmSociety } from "../Pages/Activities/MgmFilmSociety";
import { Events } from "../Pages/Activities/Events";
import { FilmFestivalAttendance } from "../Pages/Activities/FilmFestivalAttendance";
import { Methodology } from "../Pages/Activities/Methodology";
import { MaInActingForFilms } from "../Pages/Academics/Specialization/Sub-Specialization/ActingForFlims/MaInActingForFilms";
import { BaInActingForFilms } from "../Pages/Academics/Specialization/Sub-Specialization/ActingForFlims/BaInActingForFilms";
import { DiplomaInActingForFilms } from "../Pages/Academics/Specialization/Sub-Specialization/ActingForFlims/DiplomaInActingForFilms";
import { ActingShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/ActingForFlims/ActingShortPrograms";
import { MaInFilmEditing } from "../Pages/Academics/Specialization/Sub-Specialization/FilmEditing/MaInFilmEditing";
import { BaHonsFilmEditing } from "../Pages/Academics/Specialization/Sub-Specialization/FilmEditing/BaHonsFilmEditing";
import { DiplomaInFilmEditing } from "../Pages/Academics/Specialization/Sub-Specialization/FilmEditing/DiplomaInFilmEditing";
import { FilmEditingShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/FilmEditing/FilmEditingShortPrograms";
import { MaInFilmDirection } from "../Pages/Academics/Specialization/Sub-Specialization/FilmDirectionAndScreenwriting/MaInFilmDirection";
import { BaHonsFilmDirection } from "../Pages/Academics/Specialization/Sub-Specialization/FilmDirectionAndScreenwriting/BaHonsFilmDirection";
import { DiplomaInFilmDirection } from "../Pages/Academics/Specialization/Sub-Specialization/FilmDirectionAndScreenwriting/DiplomaInFilmDirection";
import { MaInProductionDesignArtDirection } from "../Pages/Academics/Specialization/Sub-Specialization/ProductionDesignAndArtDirection/MaInProductionDesignArtDirection";
import { BaHonsProductionDesignArtDirection } from "../Pages/Academics/Specialization/Sub-Specialization/ProductionDesignAndArtDirection/BaHonsProductionDesignArtDirection";
import { DiplomaInProductionDesignAndArtDirection } from "../Pages/Academics/Specialization/Sub-Specialization/ProductionDesignAndArtDirection/DiplomaInProductionDesignAndArtDirection";
import { MaInCinematography } from "../Pages/Academics/Specialization/Sub-Specialization/CinematographyDI/MaInCinematography";
import { BaHonsCinematography } from "../Pages/Academics/Specialization/Sub-Specialization/CinematographyDI/BaHonsCinematography";
import { DiplomaInCinematography } from "../Pages/Academics/Specialization/Sub-Specialization/CinematographyDI/DiplomaInCinematography";
import { CinematographyShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/CinematographyDI/CinematographyShortPrograms";
import { BaInVfxAndAnimation } from "../Pages/Academics/Specialization/Sub-Specialization/VfxAndAnimation.jsx/BaInVfxAndAnimation";
import { MaInVfxAndAnimation } from "../Pages/Academics/Specialization/Sub-Specialization/VfxAndAnimation.jsx/MaInVfxAndAnimation";
import { DiplomaInVfxAndAnimation } from "../Pages/Academics/Specialization/Sub-Specialization/VfxAndAnimation.jsx/DiplomaInVfxAndAnimation";
import { VfxAndAnimationShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/VfxAndAnimation.jsx/VfxAndAnimationShortPrograms";
import { DiplomaInSoundDesignAndMusicProduction } from "../Pages/Academics/Specialization/Sub-Specialization/SoundDesignAndMusicProduction/DiplomaInSoundDesignAndMusicProduction";
import { BaSoundDesignAndMusicProduction } from "../Pages/Academics/Specialization/Sub-Specialization/SoundDesignAndMusicProduction/BaSoundDesignAndMusicProduction";
import {
  MAInSoundDesignAndMusicProduction,
  // MaInSoundDesignAndMusicProduction,
} from "../Pages/Academics/Specialization/Sub-Specialization/SoundDesignAndMusicProduction/MAInSoundDesignAndMusicProduction";
import { FilmDirectionShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/FilmDirectionAndScreenwriting/FilmDirectionShortPrograms";
import { ProductionDesignShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/ProductionDesignAndArtDirection/ProductionDesignShortPrograms";
import { SoundDesignShortPrograms } from "../Pages/Academics/Specialization/Sub-Specialization/SoundDesignAndMusicProduction/SoundDesignShortPrograms";
import { MobileJournalism } from "../Pages/Ftii courses/MobileJournalism";
import { VisualStorytelling } from "../Pages/Ftii courses/VisualStorytelling";
import { DigitalFilmProduction } from "../Pages/Ftii courses/DigitalFilmProduction";
import { ScreenActing } from "../Pages/Ftii courses/ScreenActing";
import { FtiiCourses } from "../Pages/Ftii courses/FtiiCourses";
import { StalWartsAboutMgmFilmArts } from "../Pages/Activities/StalwartsAboutMgmFilmArts";
import { Placements } from "../Pages/Activities/Placements";
import { Masterclass } from "../Pages/Activities/Masterclass";
import { StudentReviews } from "../Pages/Activities/StudentReviews";
import { BlogDetails } from "../Pages/Activities/BlogDetails";
import { FeatureFilmProduction } from "../Pages/Activities/FeatureFilmProduction";
import { ShortFilmProduction } from "../Pages/Activities/ShortFilmProduction";
import { ProfessionalProjects } from "../Pages/Activities/ProfessionalProjects";
import { MusicProduction } from "../Pages/Activities/MusicProduction";
// import { DiplomaFilmDirection } from "../Pages/Academics/Programs/SubPrograms/DiplomaFilmDirection";
import routesData from "../assets/json Files/routesData.json";
import { Registration } from "../Pages/MiscellanousPages/Registration";
import { RegistrationLeads } from "../Pages/MiscellanousPages/RegistrationLeads";
import { Walkthrough } from "../Pages/MiscellanousPages/Walkthrough";
import { WalkthroughLeads } from "../Pages/MiscellanousPages/WalkthroughLeads";
// const saveScrollPosition = (position) => {
//   localStorage.setItem("scrollPosition", position);
// };

// const getSavedScrollPosition = () => {
//   return Number(localStorage.getItem("scrollPosition"));
// };

export const Routing = () => {
  // const [isAtBottom, setIsAtBottom] = useState(false);
  const location = useLocation();
  // const [favicon, setFavicon] = useState("/imgs/favicon/favicon.ico");
  const [pageTitle, setPageTitle] = useState("Default Title");
  const [pageDescription, setPageDescription] = useState("Default description");
  const [favicon, setFavicon] = useState("/imgs/favicon/2favicon.ico");
  const [ac192, setAc192] = useState(
    "/imgs/favicon1/android-chrome-192x192.png"
  );
  const [ac512, setAc512] = useState(
    "/imgs/favicon1/android-chrome-512x512.png"
  );
  const [ati, setAti] = useState("/imgs/favicon1/apple-touch-icon.png");
  const [fi16, setFi16] = useState("/imgs/favicon1/favicon-16x16.png");
  const [fi32, setFi32] = useState("/imgs/favicon1/favicon-32x32.png");
  const [msTile, setmsTile] = useState("/imgs/favicon1/mstile-150x150.png");
  const [SPsvg, setSPsvg] = useState("/imgs/favicon1/safari-pinned-tab.svg");
  useEffect(() => {
    // Update the favicon URL based on the current URL path
    if (location.pathname === "/registration") {
      setFavicon(`${require("../assets/imgs/favicon1/2favicon.ico")}`);
      setAc192(
        `${require("../assets/imgs/favicon1/android-chrome-192x192.png")}`
      );
      setAc512(
        `${require("../assets/imgs/favicon1/android-chrome-512x512.png")}`
      );
      setAti(`${require("../assets/imgs/favicon1/apple-touch-icon.png")}`);
      setFi16(`${require("../assets/imgs/favicon1/favicon-16x16.png")}`);
      setFi32(`${require("../assets/imgs/favicon1/favicon-32x32.png")}`);
      setmsTile(`${require("../assets/imgs/favicon1/mstile-150x150.png")}`);
      setSPsvg(`${require("../assets/imgs/favicon1/safari-pinned-tab.svg")}`);
      setPageTitle("Registration");
      setPageDescription("Description for Registration");
    } else {
      setFavicon("/imgs/favicon/favicon.ico");
      setAc192(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setAc512(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setAti(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setFi16(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setFi32(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setmsTile(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      setSPsvg(`${require("../assets/imgs/favicon/2favicon.ico")}`);
      const currentRoute = routesData.routes.find(
        (route) => route.path === location.pathname
      );
      if (currentRoute) {
        setPageTitle(currentRoute.name + " - MGMU School of Film Arts");
        setPageDescription("Description for " + currentRoute.name);
      } else {
        setPageTitle("MGM School of Film Arts");
        setPageDescription("Default description");
      }
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const position = window.pageYOffset;
  //     const isBottom =
  //       window.innerHeight + position === document.documentElement.offsetHeight;
  //     setIsAtBottom(isBottom);
  //     saveScrollPosition(position);
  //   };

  //   setIsAtBottom(
  //     window.innerHeight + getSavedScrollPosition() ===
  //       document.documentElement.offsetHeight
  //   );
  //   window.addEventListener("scroll", onScroll);

  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isAtBottom) {
  //     window.scrollTo({
  //       top: document.documentElement.offsetHeight - window.innerHeight,
  //       behavior: "smooth",
  //     });
  //   } else {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // }, [location.pathname, isAtBottom]);

  // useEffect(() => {
  //   const savedPosition = getSavedScrollPosition();
  //   if (savedPosition) {
  //     window.scrollTo({ top: savedPosition, behavior: "smooth" });
  //   }
  // }, []);

  // const getTitleAndDescription = () => {
  //   const currentRoute = routesData.routes.find(
  //     (route) => route.path === location.pathname
  //   );

  //   if (currentRoute) {
  //     return {
  //       // title: currentRoute.name + ' Title',
  //       title: currentRoute.name + " - MGMU School of Film Arts",
  //       description: "Description for " + currentRoute.name,
  //     };
  //   } else {
  //     return {
  //       title: "Default Title",
  //       description: "Default description",
  //     };
  //   }
  // };

  // const { title, description } = getTitleAndDescription();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
        <Link rel="apple-touch-icon" sizes="180x180" to={ati} />
        <Link rel="icon" type="image/png" sizes="32x32" to={fi32} />
        <Link rel="icon" type="image/png" sizes="16x16" to={fi16} />
        <Link rel="mask-icon" to={SPsvg} color="#845437" />
        <meta property="og:image" content={ac192} />
        <meta property="og:image" content={ac512} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <Routes>
        {/* Homepage Routings */}
        <Route path="/" exact element={<Homepage />} />
        <Route path="/index/" element={<Homepage />} />

        {/* About */}
        <Route path="/about-mgm-university/" element={<MgmUniversity />} />
        <Route path="/our-pillars/" element={<OurPillars />} />
        <Route path="/about-mgm-film-arts/" element={<AboutFilmArts />} />
        <Route path="/faculty" element={<Faculty />} />

        {/* Academic - programs */}
        <Route
          path="/certificate-program-2"
          element={<DiplomainFilmmaking />}
        />
        <Route path="/graduation-program" element={<GraduationProgram />} />
        <Route path="/ma-in-filmmaking" element={<MainFilmMaking />} />
        <Route
          path="/certificate-programs-in-film-making"
          element={<ShortPrograms />}
        />
        <Route path="/admission-procedure" element={<AdmissionProcedure />} />

        {/* Academic - Sub-programs */}
        <Route
          path="/diploma-in-acting-for-films"
          element={<DiplomaInActingForFilms />}
        />
        <Route
          path="/diploma-in-film-direction"
          element={<DiplomaInFilmDirection />}
        />

        {/* Academic Specialisation */}
        <Route path="/acting-for-films" element={<ActingForFilms />} />
        <Route path="/film-editing" element={<FilmEditing />} />
        <Route
          path="/film-direction"
          element={<FilmDirectionScreenwriting />}
        />
        <Route
          path="/production-design-art-direction"
          element={<ProductionDesignArtDirection />}
        />
        <Route path="/cinematography" element={<CinematographyDI />} />
        <Route
          path="/sound-design-music-production"
          element={<SoundDesignMusicProduction />}
        />
        <Route path="/vfx-animation" element={<VFXAnimation />} />

        {/* Academic programs specialisation acting-for-films Sub-Specialisation*/}
        <Route
          path="/ma-in-acting-for-films"
          element={<MaInActingForFilms />}
        />
        <Route
          path="/ba-hons-acting-for-films"
          element={<BaInActingForFilms />}
        />
        {/* <Route
          path="/diploma-in-film-editing"
          element={<DiplomaInActingForFilms />}
        /> */}
        <Route
          path="/acting-short-programs"
          element={<ActingShortPrograms />}
        />

        {/* Academic programs specialisation film-editing Sub-Specialisation*/}
        <Route path="/ma-in-film-editing" element={<MaInFilmEditing />} />
        <Route path="/ba-hons-film-editing" element={<BaHonsFilmEditing />} />
        <Route
          path="/diploma-in-film-editing"
          element={<DiplomaInFilmEditing />}
        />
        <Route
          path="/film-editing-short-programs"
          element={<FilmEditingShortPrograms />}
        />

        {/* Academic programs specialisation film-direction-and-screen-writing Sub-Specialisation*/}
        <Route
          path="/ba-hons-film-direction"
          element={<BaHonsFilmDirection />}
        />
        <Route
          path="/ba-hons-production-design-art-direction"
          element={<BaHonsProductionDesignArtDirection />}
        />
        <Route path="/ma-film-direction" element={<MaInFilmDirection />} />
        <Route
          path="/short-programs"
          element={<FilmDirectionShortPrograms />}
        />

        {/* Academic programs specialisation production-design-and-art-direction Sub-Specialisation*/}
        <Route
          path="/ma-in-production-design-art-direction-2"
          element={<MaInProductionDesignArtDirection />}
        />
        <Route
          path="/production-design-short-programs"
          element={<ProductionDesignShortPrograms />}
        />

        {/* Academic programs specialisation Cinamatography Sub-Specialisation*/}
        <Route path="/ma-in-cinematography" element={<MaInCinematography />} />
        <Route
          path="/ba-hons-cinematography"
          element={<BaHonsCinematography />}
        />
        <Route
          path="/diploma-in-cinematography"
          element={<DiplomaInCinematography />}
        />
        <Route
          path="/cinematography-short-programs"
          element={<CinematographyShortPrograms />}
        />
        <Route
          path="/diploma-in-production-design-and-art-direction"
          element={<DiplomaInProductionDesignAndArtDirection />}
        />

        {/* Academic programs specialisation vfx-animation Sub-Specialisation*/}
        <Route
          path="/ba-hons-vfx-animation"
          element={<BaInVfxAndAnimation />}
        />
        <Route path="/ma-in-vfx-animation" element={<MaInVfxAndAnimation />} />
        <Route
          path="/diploma-in-vfx-and-animation"
          element={<DiplomaInVfxAndAnimation />}
        />
        <Route
          path="/vfx-animation-short-programs"
          element={<VfxAndAnimationShortPrograms />}
        />

        {/* Academic programs specialisation sound-design-and-music-production Sub-Specialisation*/}
        <Route
          path="/diploma-in-sound-design-and-music-production"
          element={<DiplomaInSoundDesignAndMusicProduction />}
        />
        <Route
          path="/ba-hons-sound-design-music-production"
          element={<BaSoundDesignAndMusicProduction />}
        />
        <Route
          path="/ma-in-sound-design-music-production-022"
          element={<MAInSoundDesignAndMusicProduction />}
        />
        <Route
          path="/sound-design-short-programs"
          element={<SoundDesignShortPrograms />}
        />

        {/* Activities */}
        <Route path="/audition" element={<Audition />} />
        <Route path="/film-productions" element={<FilmProduction />} />
        <Route
          path="/feature-film-production"
          element={<FeatureFilmProduction />}
        />
        <Route
          path="/mgm-university-film-society"
          element={<MgmFilmSociety />}
        />
        <Route path="/events" element={<Events />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route
          path="/film-festival-attendance"
          element={<FilmFestivalAttendance />}
        />
        <Route path="/methodology" element={<Methodology />} />
        <Route
          path="/short-film-production"
          element={<ShortFilmProduction />}
        />
        <Route
          path="/professional-projects"
          element={<ProfessionalProjects />}
        />
        <Route path="/music-production" element={<MusicProduction />} />

        {/* Activities-CampusLife */}
        <Route
          path="/stalwarts-about-mgm-film-arts"
          element={<StalWartsAboutMgmFilmArts />}
        />
        <Route path="/placements" element={<Placements />} />
        <Route path="/Masterclass" element={<Masterclass />} />
        <Route path="/student-reviews" element={<StudentReviews />} />
        <Route path="/gallery" element={<Gallery />} />

        {/* Facilities */}
        <Route path="/ftii-courses" element={<FtiiCourses />} />
        <Route path="/mobile-journalism" element={<MobileJournalism />} />
        <Route path="/visual-storytelling" element={<VisualStorytelling />} />
        <Route
          path="/digital-film-production"
          element={<DigitalFilmProduction />}
        />
        <Route path="/screen-acting" element={<ScreenActing />} />

        {/* Facilities */}
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/contact-us" element={<Contact />} />
        {/* Miscellanous */}
        <Route path="/apply-for-workshop" element={<Workshop />} />
        <Route path="/workshop-leads" element={<WorkshopLeads />} />
        <Route path="/apply-for-scholarship" element={<Scholarship />} />
        <Route path="/scholarship-leads" element={<ScholarshipLeads />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/registration-leads" element={<RegistrationLeads />} />
        <Route path="/walkthrough" element={<Walkthrough />} />
        <Route path="/walkthrough-leads" element={<WalkthroughLeads />} />
      </Routes>
    </>
  );
};
