import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import "./assets/css/all.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/master.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/progressbar.css";
import "./assets/css/swiper-bundle.min.css";
import { PreLoadernCursor } from "../src/Components/Preloader/PreLoadernCursor";
import Cursor from "./Components/Cursor/Cursor";
import { Footer } from "./Components/Common/Footer";
import { Header } from "./Components/Common/Header";
import { Routing } from "./Routing/RoutingModule";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { RoutingModule } from "./Routing/RoutingModule";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [scaling, setscaling] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const position = window.pageYOffset;
      const isBottom =
        window.innerHeight + position === document.documentElement.offsetHeight;
      setIsAtBottom(isBottom);
      localStorage.setItem("scrollPosition", position);
    };

    setIsAtBottom(
      window.innerHeight + Number(localStorage.getItem("scrollPosition")) ===
      document.documentElement.offsetHeight
    );
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      window.scrollTo({
        top: document.documentElement.offsetHeight - window.innerHeight,
        behavior: "smooth",
      });
    }
  }, [isAtBottom]);

  // Set scroll position on page load
  useEffect(() => {
    const savedPosition = Number(localStorage.getItem("scrollPosition"));
    if (savedPosition) {
      window.scrollTo({ top: savedPosition, behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Cursor scaling={scaling} />
      {isLoading ? (
        <PreLoadernCursor />
      ) : (
        <div className="App" id=" ">
          {/* <BrowserRouter basename={"/mgmschoolfilmarts1"}> */}
          <BrowserRouter>
            {/* <Header /> */}
            <div>
              <Routing />
              <LazyLoadComponent>
                <Footer />
              </LazyLoadComponent>
            </div>
          </BrowserRouter>
        </div>
      )}
    </>
  );
}

export default App;
