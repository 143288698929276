import React, { useLayoutEffect, useRef } from "react";
import img from "../../../assets/imgs/specilization/acting.jpg";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
import { Fade } from "react-reveal";
export const AboutFilmArts = () => {
  const data = [
    {
      id: 1,
      image: require("../../../assets/imgs/team/mentors/1.jpg"),
      name: "Dr. N. Chandra",
      role: "Director, Producer",
    },
    {
      id: 2,
      image: require("../../../assets/imgs/team/mentors/2.jpg"),
      name: "B. Lenin",
      role: "Editor, Director",
    },
    {
      id: 3,
      image: require("../../../assets/imgs/team/mentors/3.jpg"),
      name: "Mahesh Aney",
      role: "Cinematographer, Director",
    },
    {
      id: 4,
      image: require("../../../assets/imgs/team/mentors/4.jpg"),
      name: "Chandrakant Kulkarni",
      role: "Director, Producer",
    },
    // {
    //   id: 5,
    //   image: require("../../../assets/imgs/team/mentors/5.jpg"),
    //   name: "Ashok Rane",
    //   role: "Writer, Director, Critic",
    // },
    {
      id: 6,
      image: require("../../../assets/imgs/team/mentors/6.jpg"),
      name: "Makarand Anaspure",
      role: "Anaspure directed the Marathi film Dambis, in 2011",
    },

    {
      id: 7,
      image: require("../../../assets/imgs/team/mentors/7.jpg"),
      name: "Yateen Karyekar",
      role: "Actor",
    },
    {
      id: 8,
      image: require("../../../assets/imgs/team/mentors/8.jpg"),
      name: "Mukta Barve",
      role: "Actor, Producer",
    },
    {
      id: 9,
      image: require("../../../assets/imgs/team/faculty/1.jpg"),
      name: "Vishwajeet Joshi",
      role: "Music Director",
    },
    {
      id: 10,
      image: require("../../../assets/imgs/team/faculty/2.jpg"),
      name: "Avinash Chandrachood",
      role: "Music Director",
    },
    {
      id: 11,
      image: require("../../../assets/imgs/team/faculty/3.jpg"),
      name: "Dnyanesh Zoting",
      role: "Is writer, and director",
    },
    {
      id: 12,
      image: require("../../../assets/imgs/team/faculty/4.jpg"),
      name: "Jayprad Desai",
      role: "Is a creator of Web Series Hutatma",
    },
    {
      id: 13,
      image: require("../../../assets/imgs/team/faculty/5.jpg"),
      name: "Milind Jog",
      role: "Cinematographer",
    },
    {
      id: 14,
      image: require("../../../assets/imgs/team/faculty/6.jpg"),
      name: "Ginnilal Salunke",
      role: "VFX Director",
    },
    {
      id: 15,
      image: require("../../../assets/imgs/team/faculty/7.jpg"),
      name: "Veerdhawal Patil",
      role: "Cinematographer",
    },
    {
      id: 16,
      image: require("../../../assets/imgs/team/faculty/8.jpg"),
      name: "Karan Tandale",
      role: "Cinematographer",
    },
    {
      id: 17,
      image: require("../../../assets/imgs/team/faculty/9.jpg"),
      name: "Sandeep Inamke",
      role: "Production Designer, Art Director",
    },
    {
      id: 18,
      image: require("../../../assets/imgs/team/faculty/10.jpg"),
      name: "Sunny Sharma",
      role: "Animator",
    },
    {
      id: 19,
      image: require("../../../assets/imgs/team/faculty/11.jpg"),
      name: "Sahil Kulkarni",
      role: "Music Director",
    },
    {
      id: 20,
      image: require("../../../assets/imgs/team/faculty/12.jpg"),
      name: "Jaee Kadam",
      role: "Cinematographer",
    },
    {
      id: 21,
      image: require("../../../assets/imgs/team/faculty/13.jpg"),
      name: "Sampada Gejji",
      role: "Production Designer, Art Director",
    },
    {
      id: 22,
      image: require("../../../assets/imgs/team/faculty/14.jpg"),
      name: "Shiv Kadam",
      role: "Writer, Director",
    },
    {
      id: 23,
      image: require("../../../assets/imgs/team/faculty/15.jpg"),
      name: "Yogita Mahajan",
      role: "Seasoned theatre professional and research scholar",
    },
    {
      id: 24,
      image: require("../../../assets/imgs/team/faculty/16.jpg"),
      name: "Kalyani Shinde",
      role: "She is our first batch alumni who then joined MGM School of film Arts as an Editing program coordinator",
    },
    {
      id: 25,
      image: require("../../../assets/imgs/team/faculty/17.jpg"),
      name: "Sanket Dhotkar",
      role: "Sound Engineer, 5.1 Mixing Engineer",
    },
  ];
  const portfolioData = [
    {
      src: require("../../../assets/imgs/portfolio/1/1.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "DI Lab",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/2.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Super 35mm Cameras",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/3.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Cine Lenses",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/4.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Shooting Locations",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/5.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Shooting Floor",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/6.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Cine Lights",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/7.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "FCP Editing Studios",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/8.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Sound Studio",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/9.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "VFX & Animation Lab",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/10.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Chitrapati Dr.V. Shantaram",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/11.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Chroma floor",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/12.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "BMD pocket camera",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/17.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Swimming Pool",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/18.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Sports Club",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/19.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "Hostel",
    },
    {
      src: require("../../../assets/imgs/portfolio/1/20.jpg"),
      className: "img-fluid",
      alt: "",
      h3: "90.8 radio station",
    },
  ];
  const sectionTitle = "About MGM Film Arts";
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 pt-1 text-white">
                    {sectionTitle.split("").map((chars, index) => (
                      <Fade key={index} left duration={600} delay={index * 50}>
                        <span>{chars}</span>
                      </Fade>
                    ))}{" "}
                    <br />
                  </h2>
                  {/* <!-- <p className="mt-5 text-white">If you have the emotion, it infects you and the audience
                                    </p> --> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title onerem">MGM School of Film Arts</h2>
                  <div className="job__detail-content">
                    <p>
                      MGM School of Film Arts is one of the most updated film
                      schools in India providing easy access to the students of
                      the 'State-Of-The-Art' Film making facilities.
                    </p>
                    <p>
                      MGM Film Arts firmly believes that film is an art and
                      every student of MGM Film Arts needs to be groomed as an
                      artist first.
                    </p>
                    <p>
                      Hence, alongside robust facilities like film cameras,
                      editing studios, sound recording studio, color lab, there
                      is top-notch mentoring from experts in the filmmaking
                      field.
                    </p>
                    <p>
                      MGM School of Film Arts is a constituent of the
                      well-reputed MGM College of Journalism & Mass
                      Communication, under MGM University, state self-financed
                      university with 2(f) status of UGC.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faculty">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <h2 className="sec-title animation__char_come text-center">
                  Faculty &amp; Mentors
                </h2>
              </div>
              <div className="row">
                {data.map((item) => (
                  <div
                    className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6 "
                    key={item.id}
                  >
                    <Link to="javascript:;">
                      <img src={item.image} className="img-fluid" alt="" />
                      <div className="team__info">
                        <h4 className="team__member-name-6">{item.name}</h4>
                        <h5 className="team__member-role-6">{item.role}</h5>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Portfolio area start --> */}
        <section className="portfolio-v5 portfolio__project ">
          <div className="container text-center">
            <div className="line-3"></div>
            <div className="row ">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title sub-section-title mb-4 animation__char_come">
                    <span className="fst-italic pe-1">'State-of-the-art'</span>{" "}
                    Facilities
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              {portfolioData.map((item, index) => (
                <div
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6"
                  key={index}
                >
                  <img
                    src={item.src}
                    className={item.className}
                    alt={item.alt}
                  />
                  <h3>{item.h3}</h3>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <!-- Portfolio area end --> */}
      </main>
    </>
  );
};
