import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Accordion } from "react-bootstrap";
export const MaInVfxAndAnimation = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-vfx pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/vfx.jpg?v=0.1.9) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    MA In VFX & Animation
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper job__detail-wrapper-20">
                  <h2 className="sec-title sub-section-title">
                    MA with Major in Vfx &amp; Animation
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Our 2-Year MA in Filmmaking (With VFX & Animation Major)
                      is designed to make our student excel in VFX & Animation
                      for Films. We offer professional film education in a
                      practical setting which is supported by our in-house
                      studios. Our teachers are industry professionals, working
                      in film and television.
                    </p>
                    <p>
                      Throughout the course you will gain a thorough knowledge
                      in the key areas of the process of VFX, from script to
                      screen, and become multi-skilled and adaptable, favouring
                      the art of collaborative storytelling rather than working
                      as a solo artist. You will learn how to quickly adapt to
                      the expectations of the director and that will help you
                      start and continue your career in the industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A VFX Director/Animator will excel in his craft only when
                      he will master all the filmmaking elements. A VFX Artist
                      must not only know details of VFX but also the camera and
                      Lenses, Lighting Patterns, Editing, Dubbing. Hence we
                      provide all standard filmmaking facilities to the VFX
                      student from day one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="0" id="accordionExample">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>
                            Easy access to Chroma, Miniature, Dubbing, Editing
                            Studios
                          </li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Masterclass by Vfx Directors, Technicians from
                            Industry
                          </li>
                          <li>
                            Participation in compulsory classroom projects in
                            every semester
                          </li>
                          <li>
                            Chance to Vfx and Animation in Full Length Feature
                            Film in 4th Semester as compulsory Feature Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 2 Years
                            (4 Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Eligibility</span>: Any
                            Graduate
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 12 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000/- + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <a href="contact-us">Click here</a> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span class="fw-bold">1. Ginilal Salunkhe</span>
                            Vfx Director
                          </li>
                          <li>
                            <span class="fw-bold">2. Sunny Sharma</span>
                            Animation Artist
                          </li>
                          <li>
                            <span class="fw-bold">3. Nitin Baid</span>
                            Editor
                          </li>
                          <li>
                            <span class="fw-bold">4. Shiv Kadam</span>
                            Director, Writor, Academician
                          </li>
                          <li>
                            <span class="fw-bold">5. Shishir Khoshua </span>
                            3D AnimatorShishir Khoshua 3D Animator
                          </li>
                          <li>
                            <span class="fw-bold">6. Milind Jog </span>
                            Cinematographer
                          </li>
                          <li>
                            <span class="fw-bold">7. Kalyani Shinde </span>
                            Editor
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <a href="facilities">Click here</a> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Prof. Jaee Kadam</span> 8208881783
                    / 9699039209
                  </p>
                  <p>jkadam@mgmu.ac.in</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
